import React from 'react'

export const About = () => {
  return (
    <div>
        <h3>kritam dahal</h3>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Placeat, quasi adipisci corporis cupiditate officiis ut error? Facere enim vero, sunt mollitia earum debitis quod commodi deserunt sapiente magnam, dolorum iste.</p>
    </div>
  )
}
