import "./App.css";
import React, { useState, useEffect } from "react";
import Header from "./componets/Header";
import Todos from "./componets/Todos";
import Footer from "./componets/Footer";
import Todo from "./componets/Todo";
import AddTodos from "./componets/AddTodos";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
  Navigate,
} from "react-router-dom";
import { About } from "./componets/About";

function App() {
  let initTodo;
  if (localStorage.getItem("todos") === null) {
    initTodo = [];
  } else {
    initTodo = JSON.parse(localStorage.getItem("todos"));
  }
  const onDelete = (todo) => {
    console.log("I am ondelete of todo", todo);
    // deleting this way in react does not work
    // let index = todos.indexOf(todo);
    // todos.splice(index,1);
    setTodos(
      todos.filter((e) => {
        return e !== todo;
      })
    );
    localStorage.setItem("todos", JSON.stringify(todos));
  };
  const addTodo = (title, desc) => {
    console.log("To add todo", title, desc);
    let sno;
    if (todos.length === 0) {
      sno = 1;
    } else {
      sno = todos[todos.length - 1].sno1 + 1;
    }
    const myTodo = {
      sno1: sno,
      title: title,
      desc: desc,
    };
    setTodos([...todos, myTodo]);
    console.log(myTodo);
  };
  const [todos, setTodos] = useState(initTodo);
  useEffect(() => {
    localStorage.setItem("todos", JSON.stringify(todos));
  }, [todos]);
  return (
    <>
      <Router>
        <Header title="My-TODO-LIST" searchBar={true} />
        <Routes>
          <Route exact path="/" element={<AddTodos addTodo={addTodo} />}/>
          <Route exact path="/todos" element={<Todos todos={todos} onDelete={onDelete} />}/>

          {/* <Route
            path="/"
            element={() => {
              return (
                <>
                  <AddTodos addTodo={addTodo} />
                  
                </>
              );
            }}
          /> */}
          <Route exact path="/about" element={<About/>}/>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
