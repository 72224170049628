import React from 'react'

function Todo({todo,onDelete}) {
  return (
   <div>
      <h3>{todo.sno1}</h3>
      <h4>{todo.title}</h4>
      <p>{todo.desc}</p>
      <button className="btn btn-sm btn-danger" onClick={()=>{onDelete(todo)}}>Delete</button>
   </div>
   
  );
}

export default Todo