import React from 'react'

function Footer() {
  
  return (
    <footer className="footer bg-dark text-light py-3" >
      <p className="text-center "> Copyright &copy;My Todos.com</p>
     
    </footer>
  )
}

export default Footer