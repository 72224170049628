import React from 'react'
import Todo from '../componets/Todo'

function Todos(props) {
  let myStyle ={
    minHeight :"100vh",
  }
  return (
    <div className="container my-3"style={myStyle}>
      <h3 className=' my-3'>Todos list</h3>
      {props.todos.length===0?"NO Todos to display": props.todos.map((todo)=>{
        return(
        <> 
        <Todo todo= {todo} key = {todo.sno} onDelete={props.onDelete}/>
        <hr />
        </>
        )
      })}
      
      
     
    </div>
  )
}

export default Todos